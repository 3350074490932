<template>
  <model-edit :model-data="modelData" :compare-data="compareData" @updateModelAppended="checkUpdatedData" @updateProfile="updateProfile">
    <template v-slot:header>
      <site-header
        backRouteName="models"
        backText="All Models"
        :title="$_changeModelProperty(modelData, 'name')"
        :sub-title="$_t('subTitle')"
        :login="login"
      ></site-header>
    </template>
  </model-edit>
</template>

<script>
import ModelRepository from "../abstraction/repository/modelRepository";
let modelRepository = new ModelRepository();
import ModelEdit from "./ModelEdit.vue";
export default {
  components: {
    ModelEdit,
  },
  data() {
    return {
      compareData:[],
      login: true,
      modelData: {
        loading: false,
      },
      modelId: this.$route.params.id,
    };
  },
  methods: {
    async loadModelData() {
      this.modelData.loading = true;
      this.modelData = await modelRepository.show(this.modelId);
      this.modelData.loading = false;
    },
    checkUpdatedData(){
      let _modelData = this.modelData;
      this.modelData = _modelData.model_updated_not_appended

      for(const item in this.modelData){
          if(typeof _modelData[item] != 'undefined'){
            if(_modelData[item]!=this.modelData[item]){
              this.compareData.push(item)
            }
        }
      }
      this.modelData.attrValsSwitch=_modelData.attrValsSwitch
      this.modelData.attributeValues=_modelData.attributeValues
      this.modelData.attributeValuesId=_modelData.attributeValuesId
      this.modelData.model.thumbnail=_modelData.model.thumbnail
    },
    async updateProfile(form) {
      const response = await modelRepository.updateByAdmin(this.modelId, form);
      if (response) {
        this.$router.push({
          name: "models",
          params: {
            locale: this.$_getlocaleParam(),
          },
        });
      }
    },
  },
  created() {
    this.loadModelData();
  },
};
</script>

<style></style>
